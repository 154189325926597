export enum TooltipPosition {
  Top = 'top',
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right',
}

export enum TooltipAlignment {
  Start = 'start',
  Center = 'center',
  End = 'end',
}

export enum TooltipSize {
  Default = 'default',
  Large = 'large',
}
