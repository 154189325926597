import { animate, style, transition, trigger } from '@angular/animations';
import { NgTemplateOutlet } from '@angular/common';
import { Component, HostBinding, TemplateRef } from '@angular/core';

import { TooltipAlignment, TooltipPosition, TooltipSize } from '@design/overlays/tooltip/tooltip';

@Component({
  selector: 'cc-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  standalone: true,
  imports: [NgTemplateOutlet],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0, scale: 0.8 }),
        animate(
          '0.1s ease-in-out',
          style({
            opacity: 1,
            scale: 1,
          }),
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, scale: 1 }),
        animate(
          '0.1s ease-in-out',
          style({
            opacity: 0,
            scale: 0.8,
          }),
        ),
      ]),
    ]),
  ],
})
export class TooltipComponent {
  content: string | TemplateRef<unknown>;
  position: TooltipPosition = TooltipPosition.Top;
  alignment: TooltipAlignment = TooltipAlignment.Center;
  maxWidth: string = '184px';
  arrow: boolean = true;
  size: TooltipSize = TooltipSize.Default;

  @HostBinding('class')
  get hostClass(): string {
    if (this.arrow) {
      return `${this.position} cc-tooltip--arrow`;
    }

    return this.position;
  }

  get tooltipClass(): string {
    return `cc-tooltip--${this.position}-${this.alignment} cc-tooltip--size-${this.size}`;
  }

  get template(): TemplateRef<unknown> | null {
    if (this.content instanceof TemplateRef) return this.content;
    return null;
  }

  get text(): string | null {
    if (typeof this.content === 'string') return this.content;
    return null;
  }

  @HostBinding('@fadeInOut')
  get fadeInOut(): boolean {
    return true;
  }
}
