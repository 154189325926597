import { type ConnectedPosition, Overlay, OverlayConfig, type OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  Directive,
  ElementRef,
  HostListener,
  inject,
  Input,
  type OnDestroy,
  type TemplateRef,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';

import { TooltipAlignment, TooltipPosition, TooltipSize } from '@design/overlays/tooltip/tooltip';
import { TooltipComponent } from '@design/overlays/tooltip/tooltip.component';

@Directive({
  selector: '[ccTooltip]',
  standalone: true,
})
export class TooltipDirective implements OnDestroy {
  @Input('ccTooltip')
  tooltipContent: string | TemplateRef<unknown>;

  @Input()
  tooltipPosition: TooltipPosition = TooltipPosition.Top;

  @Input()
  tooltipAlignment: TooltipAlignment = TooltipAlignment.Center;

  @Input()
  tooltipOffset: number = 2;

  @Input()
  tooltipMaxWidth: string = '184px';

  @Input()
  tooltipDisabled: boolean = false;

  @Input()
  tooltipArrow: boolean = true;

  @Input()
  tooltipSize: TooltipSize = TooltipSize.Default;

  @Input()
  removeOnClick: boolean = true;

  @Input()
  showOnlyOnOverflow: boolean = false;

  private overlayRef: OverlayRef;

  private readonly overlay = inject(Overlay);
  private readonly elementRef = inject(ElementRef);
  private readonly viewContainerRef = inject(ViewContainerRef);
  private readonly renderer = inject(Renderer2);

  @HostListener('mouseenter')
  showTooltip(): void {
    if (this.tooltipDisabled) return;

    if (this.showOnlyOnOverflow && !this.isOverflowed()) return;

    const basePosition = this.getConnectedPosition(this.tooltipPosition, this.tooltipAlignment, this.tooltipOffset);

    const positionStrategy = this.overlay.position().flexibleConnectedTo(this.elementRef).withPositions([basePosition]);

    const overlayConfig = new OverlayConfig({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });

    this.overlayRef = this.overlay.create(overlayConfig);

    const tooltipPortal = new ComponentPortal(TooltipComponent, this.viewContainerRef);
    const tooltipReference = this.overlayRef.attach(tooltipPortal);

    this.renderer.addClass(this.elementRef.nativeElement, 'cc-overlay-active');

    tooltipReference.instance.content = this.tooltipContent;
    tooltipReference.instance.position = this.tooltipPosition;
    tooltipReference.instance.alignment = this.tooltipAlignment;
    tooltipReference.instance.maxWidth = this.tooltipMaxWidth;
    tooltipReference.instance.arrow = this.tooltipArrow;
    tooltipReference.instance.size = this.tooltipSize;
  }

  @HostListener('mouseleave')
  removeTooltip(): void {
    if (this.overlayRef && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();

      setTimeout((): void => {
        if (this.overlayRef && !this.overlayRef.hasAttached())
          this.renderer.removeClass(this.elementRef.nativeElement, 'cc-overlay-active');
      }, 100);
    }
  }

  @HostListener('click')
  onClick(): void {
    if (this.removeOnClick) this.removeTooltip();
  }

  ngOnDestroy(): void {
    if (this.overlayRef && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
  }

  private getConnectedPosition(
    position: TooltipPosition,
    alignment: TooltipAlignment,
    offset: number,
  ): ConnectedPosition {
    switch (position) {
      case TooltipPosition.Top:
      case TooltipPosition.Bottom: {
        return {
          originX:
            alignment === TooltipAlignment.Center ? 'center' : alignment === TooltipAlignment.Start ? 'start' : 'end',
          originY: position === TooltipPosition.Top ? 'top' : 'bottom',
          overlayX:
            alignment === TooltipAlignment.Center ? 'center' : alignment === TooltipAlignment.Start ? 'start' : 'end',
          overlayY: position === TooltipPosition.Top ? 'bottom' : 'top',
          offsetX:
            this.elementRef.nativeElement.clientWidth / 2 < 16 && alignment !== TooltipAlignment.Center
              ? alignment === TooltipAlignment.Start
                ? (16 - this.elementRef.nativeElement.clientWidth / 2) * -1
                : 16 - this.elementRef.nativeElement.clientWidth / 2
              : 0,
          offsetY: position === TooltipPosition.Top ? -offset : offset,
        };
      }
      case TooltipPosition.Left:
      case TooltipPosition.Right: {
        return {
          originX: position === TooltipPosition.Left ? 'start' : 'end',
          originY:
            alignment === TooltipAlignment.Center ? 'center' : alignment === TooltipAlignment.Start ? 'top' : 'bottom',
          overlayX: position === TooltipPosition.Left ? 'end' : 'start',
          overlayY:
            alignment === TooltipAlignment.Center ? 'center' : alignment === TooltipAlignment.Start ? 'top' : 'bottom',
          offsetX: position === TooltipPosition.Left ? -offset : offset,
          offsetY:
            this.elementRef.nativeElement.clientHeight / 2 < 16 && alignment !== TooltipAlignment.Center
              ? alignment === TooltipAlignment.Start
                ? (16 - this.elementRef.nativeElement.clientHeight / 2) * -1
                : 16 - this.elementRef.nativeElement.clientHeight / 2
              : 0,
        };
      }
    }
  }

  private isOverflowed(): boolean {
    return this.elementRef.nativeElement.scrollWidth > this.elementRef.nativeElement.clientWidth;
  }
}
