<div
  class="cc-tooltip"
  [class]="tooltipClass"
  [style.--max-width]="maxWidth"
>
  <div class="cc-tooltip__content">
    @if (template) {
      <ng-container *ngTemplateOutlet="template"></ng-container>
    } @else {
      {{ text }}
    }
  </div>

  @if (arrow) {
    <div class="cc-tooltip__arrow"></div>
  }
</div>
